import React, { useContext, useState } from 'react'
import {
  Stack,
  Heading,
  Flex,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  IconButton,
  Box,
} from '@chakra-ui/react'
import { BoardSchema } from '../../store/boards-context'
import { truncate } from 'lodash'
import { Draggable, Droppable } from '@hello-pangea/dnd'
import { FiMoreVertical } from 'react-icons/fi'
import { MdAdd, MdEdit, MdDelete } from 'react-icons/md'
import ModalContext from '../../store/modal-context'
import { NoteSchema } from '../../store/notes-context'
import Note from '../notes/Note'
import CategoriesContext from '../../store/categories-context'
import DragContext from '../../store/drag-context'
import NewBoardDropZone from './NewBoardDropZone'

interface ColumnProps {
  board?: BoardSchema
  index: number
}

const Column: React.FC<ColumnProps> = ({ board, index }) => {
  const truncatedName = truncate(board?.name, { length: 23 })
  const [headerIsHovered, setHeaderIsHovered] = useState(false)
  const [bodyIsHovered, setBodyIsHovered] = useState(false)
  const modalCtx = useContext(ModalContext)
  const { openModal } = modalCtx
  const categoriesCtx = useContext(CategoriesContext)
  const { categories } = categoriesCtx
  const dragCtx = useContext(DragContext)
  const { dragging } = dragCtx

  const handleEditBoard = () => {
    openModal({
      modal: 'BoardModal',
      meta: {
        model: board,
      },
    })
  }

  const handleDeleteBoard = () => {
    openModal({
      modal: 'ConfirmationModal',
      meta: {
        model: board,
        confirmationAction: 'delete',
      },
    })
  }

  return (
    <Draggable
      key={`board-${board?.id}`} // this is important for reordering
      draggableId={`board-${board?.id}`}
      index={index}
    >
      {(provided, draggableSnapshot) => (
        <Flex
          direction="row"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Stack
            w="226px"
            maxW="226px"
            minW="226px"
            minH="calc(100vh - 88px)"
            mx={2}
            my={4}
            border="1px solid #e2e8f0"
            borderRadius="4px"
            bgColor={'white'}
            spacing={0}
          >
            <Flex
              justify={'center'}
              align={'center'}
              h="40px"
              fontSize="16px"
              borderBottom="1px solid #e2e8f0"
              borderRadius="4px"
              bg={'#f7fafc'}
              pos="relative"
              {...provided.dragHandleProps}
              onMouseEnter={() => setHeaderIsHovered(true)}
              onMouseLeave={() => setHeaderIsHovered(false)}
            >
              <Tooltip
                isDisabled={!(board?.name && board.name.length > 23)}
                label={board?.name}
                aria-label={board?.name}
                hasArrow
              >
                <Heading size="sm" as={'b'}>
                  {index + 1}
                  {board?.name ? '. ' + truncatedName : ''}
                </Heading>
              </Tooltip>

              {headerIsHovered && (
                <Menu placement="bottom" offset={[-29, 4]}>
                  <MenuButton
                    as={IconButton}
                    colorScheme="teal"
                    h="30px"
                    minW="30px"
                    pos="absolute"
                    right="5px"
                    top="5px"
                    rounded="full"
                  >
                    <Icon mt="4px" as={FiMoreVertical} />
                  </MenuButton>
                  <MenuList minWidth="50px">
                    <MenuItem icon={<MdEdit />} onClick={handleEditBoard}>
                      Edit
                    </MenuItem>
                    <MenuItem icon={<MdDelete />} onClick={handleDeleteBoard}>
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Flex>

            <Droppable droppableId={`board-${board?.id}`} type="note">
              {(provided, snapshot) => (
                <Flex
                  direction="column"
                  flex={1}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  px={4}
                  py={2}
                  bg={snapshot.isDraggingOver ? '#f7fafc' : 'white'}
                >
                  {(board?.notes || []).map((note: NoteSchema, i) => (
                    <Box key={note.id} id={`note-${note?.id}`}>
                      <Note
                        category={categories.find(
                          (c) => c.id === note.category_id,
                        )}
                        note={note}
                        index={i}
                        isDraggingParentColumn={draggableSnapshot.isDragging}
                        scrollElementId="kanban-board"
                      />
                    </Box>
                  ))}

                  {provided.placeholder}

                  <Flex
                    h="100%"
                    w="100%"
                    onMouseEnter={() => setBodyIsHovered(true)}
                    onMouseLeave={() => setBodyIsHovered(false)}
                  >
                    {!dragging && bodyIsHovered && (
                      <Tooltip hasArrow label="New Scene" openDelay={300}>
                        <IconButton
                          aria-label="New Scene"
                          icon={<MdAdd />}
                          variant="outline"
                          colorScheme="teal"
                          maxH="24px"
                          minW="100%"
                          borderRadius="4px"
                          onClick={() => {
                            openModal({
                              modal: 'EditNoteModal',
                              meta: {
                                board: board,
                              },
                            })
                          }}
                        />
                      </Tooltip>
                    )}
                  </Flex>
                </Flex>
              )}
            </Droppable>
          </Stack>

          {board?.id && <NewBoardDropZone boardId={board.id} />}
        </Flex>
      )}
    </Draggable>
  )
}

export default Column
